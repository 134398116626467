import React from "react"
import PropTypes from "prop-types"
import "./PageContentSection.scss"

const PageContentSection = ({ title, pageContent }) => {
  return (
    <div className="pageContentSection">
      <div className="container">
        {title && <h1>{title}</h1>}
        {pageContent && (
          <div
            className="pageContent"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: pageContent,
            }}
          />
        )}
      </div>
    </div>
  )
}

PageContentSection.propTypes = {
  title: PropTypes.string.isRequired,
  pageContent: PropTypes.string.isRequired,
}

export default PageContentSection
