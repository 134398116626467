import React from "react"
import { graphql } from "gatsby"
import { useCertificationsContent } from "../hooks/CertificationsPage/useCertificationsContent"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import PageContentSection from "../components/PageContentSection/PageContentSection"
import Footer from "../components/Footer/Footer"

const CertificationsPage = () => {
  const { title, content } = useCertificationsContent()
  return (
    <Layout>
      <Header />
      <PageContentSection title={title} pageContent={content} />
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default CertificationsPage
