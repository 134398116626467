import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useCertificationsContent = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageRo, wpPageEn } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Certifications" } }
        language: { code: { eq: RO } }
      ) {
        content
        title
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Certifications" } }
        language: { code: { eq: EN } }
      ) {
        content
        title
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return pages[language] ?? wpPageRo
}
